import React from 'react';
import './Loader.css'

const Loader = () => {
    return (
        <div id="loaderWrapper">
            <div id="loader">
                <span>Cargando</span>
            </div>
        </div>
    )
}

export default Loader
