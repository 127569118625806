import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

import './App.css';
import Loader from './components/loader/Loader';

const Inicio = React.lazy(() => import('./views/pages/landscape/Landscape'))
const SignIn = React.lazy(() => import('./views/pages/sign/signin/SignIn'))
const SignUp = React.lazy(() => import('./views/pages/sign/signup/SignUp'))
const NotFound = React.lazy(() => import('./views/pages/notfound/NotFound'))

export default function App() {
	return (
		<React.Suspense fallback={<Loader></Loader>}>
			<Router>
				<Switch>
					<Route exact path="/" component={Inicio}></Route>
					<Route exact path="/signin" component={SignIn}></Route>
					<Route exact path="/signup" component={SignUp}></Route>
					<Route path="*">
						<NotFound />
					</Route>
				</Switch>
			</Router>
		</React.Suspense>
	);
}